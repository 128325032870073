import React, { Fragment, useEffect, useState } from 'react';
import EditElement from '../EditElement';
import {
    convertToRelativeCoordinates,
    filterAllowedFields,
    getGenericFieldData,
    getResizeCursor,
    updateRectDragging,
    updateRectResizing,
} from './utils';
import { MdDelete } from 'react-icons/md';
import { css } from 'twin.macro';
import { debounce } from '../../../../_helpers';
import { AiFillCopy } from 'react-icons/ai';
import ID from '../../../../_helpers/uuid';
import {
    ActionContainer,
    CanvasContainer,
    EditDiv,
    LabelContainer,
    RemoveButton,
    ResizableRect,
    ResizeHandle,
} from './styles';
import { resizeHandlePositions } from './constants';
import EditOptionModal from './EditOptionModal';

const PdfCanvas = ({
    canvasRef,
    currentPage,
    rects,
    addFormDataElement,
    addFormDataElementOption,
    removeFormDataElement,
    removeFormDataElementOption,
    pdfDimensions,
}) => {
    const [draggingIdx, setDraggingIdx] = useState(null);
    const [resizingMetadata, setResizingMetadata] = useState({
        resizingIndex: null,
        resizingDirection: null,
    });
    const [tempRects, setTempRects] = useState(rects);
    const [selectedRectIdx, setSelectedRectIdx] = useState(null);

    const handleResizeStart = (e, index, position) => {
        e.stopPropagation();
        setResizingMetadata({
            resizingIndex: index,
            resizingDirection: position,
        });
    };

    const handleResize = debounce((e) => {
        if (resizingMetadata.resizingIndex === null || !canvasRef.current) return;

        const rect = canvasRef.current.getBoundingClientRect();
        const mouseX = e.clientX - rect.left;
        const mouseY = e.clientY - rect.top;

        updateRectResizing(
            resizingMetadata.resizingIndex,
            resizingMetadata.resizingDirection,
            { x: mouseX, y: mouseY },
            {
                width: canvasRef.current.clientWidth,
                height: canvasRef.current.clientHeight,
            },
            setTempRects
        );
    }, 5);

    const handleResizeEnd = () => {
        if (resizingMetadata.resizingIndex === null || !canvasRef.current) return;
        const resizingRectFound = tempRects.find((r, idx) => resizingMetadata.resizingIndex === idx);
        updateARectFormElement(resizingRectFound);
        setResizingMetadata({
            resizingIndex: null,
            resizingDirection: null,
        });
    };

    const updateARectFormElement = (rect) => {
        const page = rect.page;
        const rectConfig = {
            x: rect.x,
            y: rect.y,
            width: rect.width,
            height: rect.height,
            page,
        };
        if (isNaN(rect.x) || isNaN(rect.y) || isNaN(rect.width) || isNaN(rect.height)) {
            return;
        }
        const coords = convertToRelativeCoordinates(rectConfig, canvasRef, currentPage, pdfDimensions);
        const templateConfig = {
            ...coords,
            page,
        };
        const config = {
            ...rect?.config,
            templateConfig,
            rectConfig,
        };
        const field = { ...rect, config, isDragging: false };
        handleOnEdit({ field });
    };
    const handleDragStart = (e, idx) => {
        e.stopPropagation();
        const rect = canvasRef.current.getBoundingClientRect();
        const startX = e.clientX - rect.left;
        const startY = e.clientY - rect.top;

        setTempRects((prev) =>
            prev.map((r, i) =>
                i === idx ? { ...r, isDragging: true, offsetX: startX - r.x, offsetY: startY - r.y } : r
            )
        );
        setDraggingIdx(idx);
    };

    const handleDragging = debounce((e) => {
        updateRectDragging(e, canvasRef, draggingIdx, tempRects, setTempRects);
    }, 5);

    const handleDragEnd = () => {
        if (!canvasRef.current || draggingIdx === null) return;
        const isDraggingFound = tempRects.find((r) => r.isDragging);
        if (!isDraggingFound) return;
        updateARectFormElement(isDraggingFound);
        setDraggingIdx(null);
    };

    const handleOnEdit = ({ field, idx }) => {
        if (field?.config?.parentFormElement) {
            addFormDataElementOption(field);
        } else {
            const [_field] = filterAllowedFields([field]);
            addFormDataElement(_field);
        }
    };

    const cloneOption = (option) => {
        const newOption = JSON.parse(JSON.stringify(option));
        newOption.isClone = true;
        newOption.config.rectConfig.y = newOption.config.rectConfig.y + 30;
        if (newOption?.config?.parentFormElement) {
            newOption.value = ID.uuid();
            newOption.text = 'new_option';
            addFormDataElementOption(newOption);
        } else {
            newOption.config.rectConfig.x = newOption.config.rectConfig.x + 30;
            newOption.id = ID.uuid();
            delete newOption._id;
            addFormDataElement(newOption);
        }
    };

    const handleRectClick = (e, index) => {
        e.stopPropagation();
        if (index === undefined) {
            setSelectedRectIdx(null);
        } else {
            setSelectedRectIdx(index);
        }
    };

    useEffect(() => {
        setTempRects(rects);
    }, [rects]);
    return (
        <CanvasContainer
            ref={canvasRef}
            onMouseMove={
                resizingMetadata.resizingIndex !== null
                    ? handleResize
                    : (e) => {
                          handleDragging(e);
                      }
            }
            onMouseUp={() => {
                handleResizeEnd();
                handleDragEnd();
            }}
            onMouseLeave={() => {
                handleResizeEnd();
                handleDragEnd();
            }}
            onClick={handleRectClick}
        >
            {(tempRects || []).map((rect, idx) => {
                if (rect?.page === currentPage) {
                    const isOption = rect?.config?.parentFormElement;
                    const rectCoordinates = {
                        left: rect.x,
                        top: rect.y,
                        width: rect.width,
                        height: rect.height,
                    };
                    return (
                        <div key={rect?.id || rect?.value || idx}>
                            {selectedRectIdx === idx && (
                                <Fragment>
                                    <LabelContainer className="label-container" {...rectCoordinates}>
                                        <span className="ml-2">{rect?.fieldName || rect?.text}</span>
                                        <span className="ml-2 badge badge-warning">
                                            {rect?.key || rect?.config?.parentFormElement?.key || ''}
                                        </span>
                                    </LabelContainer>

                                    <ActionContainer {...rectCoordinates}>
                                        <div
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                cloneOption(rect);
                                                setSelectedRectIdx(null);
                                            }}
                                            onMouseDown={(e) => e.stopPropagation()}
                                            onMouseUp={(e) => e.stopPropagation()}
                                        >
                                            <AiFillCopy color="white" />
                                        </div>

                                        <EditDiv onClick={(e) => e.stopPropagation()}>
                                            {isOption ? (
                                                <EditOptionModal option={rect} />
                                            ) : (
                                                <EditElement
                                                    element={rect}
                                                    genericCheckboxOrRadio={getGenericFieldData().checkboxOrRadio}
                                                    onEdit={(field) => {
                                                        handleOnEdit({ field });
                                                        setSelectedRectIdx(null);
                                                    }}
                                                    color="white"
                                                />
                                            )}
                                        </EditDiv>

                                        <RemoveButton
                                            onClick={(e) => {
                                                e.stopPropagation();

                                                const id = rect?._id || rect?.id;

                                                if (id) {
                                                    removeFormDataElement(id);
                                                    setSelectedRectIdx(null);
                                                } else {
                                                    if (isOption && rect?.value) {
                                                        removeFormDataElementOption(rect.value);
                                                        setSelectedRectIdx(null);
                                                    }
                                                }
                                            }}
                                            onMouseDown={(e) => e.stopPropagation()}
                                            onMouseUp={(e) => e.stopPropagation()}
                                        >
                                            <MdDelete color="white" />
                                        </RemoveButton>
                                    </ActionContainer>
                                </Fragment>
                            )}

                            <ResizableRect
                                selected={selectedRectIdx === idx}
                                {...rectCoordinates}
                                onMouseDown={(e) => handleDragStart(e, idx)}
                                // onMouseUp={handleDragEnd}
                                onClick={(e) => handleRectClick(e, idx)}
                                isCircle={isOption?.key === 'Radio'}
                            >
                                {Object.keys(resizeHandlePositions).map((position) => {
                                    const isOption = rect?.config?.parentFormElement;

                                    if (isOption && position.includes('center')) {
                                        return null;
                                    }
                                    return (
                                        <ResizeHandle
                                            selected={selectedRectIdx === idx}
                                            className="resize-handle"
                                            key={position}
                                            cursor={getResizeCursor(position)}
                                            position={resizeHandlePositions[position]}
                                            onMouseDown={(e) =>
                                                selectedRectIdx === idx
                                                    ? handleResizeStart(e, idx, position)
                                                    : (e) => e.stopPropagation()
                                            }
                                        />
                                    );
                                })}
                            </ResizableRect>
                        </div>
                    );
                } else {
                    return <div key={idx}></div>;
                }
            })}
        </CanvasContainer>
    );
};

export default PdfCanvas;
