import React, { useState, useEffect, Fragment, forwardRef, useImperativeHandle } from 'react';
import { PDFDocument, rgb } from 'pdf-lib';
import { Document, Page } from 'react-pdf';
import loaderStyles from '../../../_elements/loader';
import { toast } from 'react-toastify';
import { css } from 'twin.macro';

const CHECKBOX_JOT_FORM =
    'https://optonome-staging.s3.amazonaws.com/chatroom/62f30a89997e8e0018b16bb9/67989ffd08e3658962397884_1738055677092_checkbox.png';
const RADIO_JOT_FORM =
    'https://optonome-staging.s3.us-east-2.amazonaws.com/chatroom/62f30a89997e8e0018b16bb9/6798a1d708e36589623978e9_1738056151743_radio.png';
const CHECKBOX_ICON_FULL =
    'https://optonome-staging.s3.us-east-2.amazonaws.com/chatroom/62f30a89997e8e0018b16bb9/679323bbbc35d1548c9d1a8e_1737696187807_checkbox_v2.png';
const RADIO_ICON_FULL =
    'https://optonome-staging.s3.us-east-2.amazonaws.com/chatroom/62f30a89997e8e0018b16bb9/679352070f28b29c9ae9d838_1737708039478_radio_v2.png';

const TemplatePreview = forwardRef(({ formTemplateUrl, formElements = [], formName = '', pdfDimension }, ref) => {
    const [pdfUrl, setPdfUrl] = useState(null);
    const [pdfBytes, setPdfBytes] = useState(null);
    const [formValues, setFormValues] = useState(null);
    const [imageValues, setImageValues] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [isBusy, setIsBusy] = useState(true);
    const [error, setError] = useState(null);

    const templateFormElements = (formElements || [])?.filter(
        (x) => x?.config?.templateConfig || x?.config?.hasTemplateConfig
    );
    useEffect(() => {
        if (!isBusy) {
            const initialValues = {};
            const imageValues = {};

            templateFormElements.forEach((_field) => {
                const field = {
                    ...(_field?.config?.templateConfig || {}),
                    ...(_field?.config?.templateConfig?.coordinates || {}),
                    ..._field,
                };
                const fieldId = _field?._id || _field?.id;
                if (field.key === 'Signature') {
                    imageValues[fieldId] = {
                        url: `${field.value}?noCache=${Math.random()}`,
                    };
                } else {
                    initialValues[fieldId] = field.value;
                }
            });

            setFormValues(initialValues);
            setImageValues(imageValues);
        }
    }, [isBusy]);

    useEffect(() => {
        if (formValues && imageValues) {
            setTimeout(() => download(true), 1000);
        }
    }, [formValues, imageValues]);

    useEffect(() => {
        if (formTemplateUrl) {
            fetchPDF(formTemplateUrl);
        }
    }, [formTemplateUrl]);

    useImperativeHandle(ref, () => ({
        download,
    }));

    const fetchPDF = async (url) => {
        try {
            const response = await fetch(url);
            const buffer = await response.arrayBuffer();
            const pdfDoc = await PDFDocument.load(buffer);
            const form = pdfDoc.getForm();
            form.flatten();
            const flattenedPdfBytes = await pdfDoc.save();
            setPdfBytes(flattenedPdfBytes);
        } catch (error) {
            console.error('Error fetching PDF:', error);
            toast.error('Failed to fetch template. Please try again.');
        } finally {
            setIsBusy(false);
        }
    };

    const drawTextInField = async (page, text, field, fontSize = 9, lineHeightFactor = 1.1) => {
        const padding = 0;
        const lineHeight = fontSize * lineHeightFactor;
        const formattedText = text.replaceAll('\r', '');
        let spacesToFitWidth = Math.ceil(field.width / Math.floor(fontSize / 2));
        spacesToFitWidth = spacesToFitWidth > pdfDimension.width ? pdfDimension.width : spacesToFitWidth;
        const wrapText = (text, maxCharsPerLine) => {
            const words = text.split(' ');
            const lines = [];
            let currentLine = '';

            words.forEach((word) => {
                if ((currentLine + word).length > maxCharsPerLine) {
                    lines.push(currentLine.trim());
                    currentLine = word + ' ';
                } else {
                    currentLine += word + ' ';
                }
            });
            if (currentLine.trim()) {
                lines.push(currentLine.trim());
            }

            return lines;
        };

        const finalLines = formattedText.split('\n').flatMap((line) => wrapText(line, spacesToFitWidth));

        let currentY = field.y;

        if (finalLines.length === 1) {
            const singleLine = finalLines[0];
            const centeredY = field.y - field.height / 2 + fontSize / 1.5;
            console.log({
                x: field.x + padding,
                y: centeredY - fontSize,
            });
            return page.drawText(singleLine, {
                x: field.x + padding,
                y: centeredY - fontSize,
                size: fontSize,
                color: rgb(0, 0, 0),
            });
        }

        finalLines.forEach((line) => {
            // if (currentY < field.y - field.height + fontSize) return;
            //rectangular overlay on text in pdf
            // const _x = field.x + padding;
            // const _y = currentY - fontSize - padding;
            // page.drawRectangle({
            //     x: _x,
            //     y: _y,
            //     width: field.width + 2,
            //     height: fontSize,
            //     color: rgb(1, 1, 1),
            //     borderColor: rgb(1, 1, 1),
            // });
            // eslint-disable-next-line no-control-regex
            const safeTextLine = line.replace(/[^\x00-\xFF]/g, '');
            page.drawText(safeTextLine, {
                x: field.x + padding,
                y: currentY - fontSize - padding,
                size: fontSize,
                color: rgb(0, 0, 0),
            });
            currentY -= lineHeight;
        });
    };

    const toAbsoluteCoordinates = (field) => ({
        x: field.x * pdfDimension.width,
        y: (1 - field.y) * pdfDimension.height,
        width: field.width * pdfDimension.width,
        height: field.height * pdfDimension.height,
    });

    const base64ToArrayBuffer = (base64) => Uint8Array.from(atob(base64.split(',')[1]), (c) => c.charCodeAt(0));

    const drawImageInField = async (pdfDoc, page, field, imageUrl) => {
        const padding = field?.padding || 0;
        const coords = field?.coordinates || field;
        const pdfX = coords.x * pdfDimension.width;
        const pdfY = pdfDimension.height - coords.y * pdfDimension.height - coords.height * pdfDimension.height;
        const pdfWidth = coords.width * pdfDimension.width;
        const pdfHeight = coords.height * pdfDimension.height;
        const availableBoxWidth = pdfWidth;
        const availableBoxHeight = pdfHeight;
        let imageBytes;

        try {
            if (imageUrl.includes('image/png')) {
                imageBytes = base64ToArrayBuffer(imageUrl);
            } else {
                imageBytes = await fetch(imageUrl).then((res) => res.arrayBuffer());
            }

            const image =
                imageUrl.includes('.png') || imageUrl.includes('image/png')
                    ? await pdfDoc.embedPng(imageBytes)
                    : await pdfDoc.embedJpg(imageBytes);

            const { width: imageWidth, height: imageHeight } = image.scale(1);
            const imageAspectRatio = imageWidth / imageHeight;

            let resizedWidth = availableBoxWidth;
            let resizedHeight = availableBoxHeight;
            if (resizedWidth / resizedHeight > imageAspectRatio) {
                resizedWidth = resizedHeight * imageAspectRatio;
            } else {
                resizedHeight = resizedWidth / imageAspectRatio;
            }

            const centerX = pdfX + (availableBoxWidth - resizedWidth) / 2;
            const centerY = pdfY + (availableBoxHeight - resizedHeight) / 2;
            page.drawImage(image, {
                x: centerX + padding / 2,
                y: centerY + padding,
                width: resizedWidth - padding * 2,
                height: resizedHeight - padding * 2,
            });
        } catch (error) {
            console.error('Error embedding image:', error);
        }
    };

    const download = async (preview = false) => {
        try {
            const pdfDoc = await PDFDocument.load(pdfBytes);
            const pages = pdfDoc.getPages();

            for (const _field of templateFormElements) {
                const fieldId = _field?._id || _field?.id;
                const field = {
                    ...(_field?.config?.templateConfig || {}),
                    ...(_field?.config?.templateConfig?.coordinates || {}),
                    ..._field,
                };
                const fieldKey = field?.key || '';
                const page = pages[field.page - 1];
                if (fieldKey === 'Signature' && imageValues[fieldId]?.url) {
                    const imageUrl = imageValues[fieldId]?.url || '';
                    await drawImageInField(pdfDoc, page, field, imageUrl);
                } else if (fieldKey === 'InputField') {
                    const absField = toAbsoluteCoordinates(field);
                    await drawTextInField(page, formValues[fieldId] || '', absField);
                } else if (fieldKey === 'Checkbox' || fieldKey === 'Radio') {
                    if (!formValues[fieldId]) {
                        continue;
                    }
                    const isRadio = fieldKey === 'Radio';
                    const selectedOptions = isRadio ? formValues[fieldId] : JSON.parse(formValues[fieldId]);
                    const selectedOptionValues = (field.data || []).filter(
                        (option) => option?.value && (selectedOptions || [])?.includes(option?.value)
                    );
                    for (let i = 0; i < selectedOptionValues.length; i++) {
                        const coordinates =
                            selectedOptionValues[i]?.config?.templateConfig?.coordinates ||
                            selectedOptionValues[i]?.config?.templateConfig ||
                            {};
                        const page = pages[coordinates.page - 1];
                        await drawImageInField(
                            pdfDoc,
                            page,
                            // { ...field, ...coordinates, coordinates, padding: 1 },
                            { ...field, ...coordinates, coordinates },
                            `${isRadio ? RADIO_JOT_FORM : CHECKBOX_JOT_FORM}?noCache=${Math.random()}`
                        );
                    }
                } else if (fieldKey === 'Select') {
                    const selectedOption =
                        (field.data || []).find((option) => option?.value === formValues[fieldId])?.text || '';
                    const absField = toAbsoluteCoordinates(field?.coordinates || field);
                    await drawTextInField(page, selectedOption, absField);
                }
            }

            const blob = new Blob([await pdfDoc.save()], {
                type: 'application/pdf',
            });
            const url = URL.createObjectURL(blob);
            if (preview) {
                setPdfUrl(url);
            } else {
                const link = document.createElement('a');
                link.href = url;
                link.download = `${formName || 'form'}.pdf`;
                link.click();
                URL.revokeObjectURL(url);
            }
        } catch (error) {
            const message = 'Error generating Template PDF. Please try again.';
            console.error(message, error);
            toast.error(message);
            setError(message);
        }
    };

    const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);

    if (error) {
        return <div className="text-danger text-center mt-4"> {error} </div>;
    }

    return (
        <Fragment>
            {pdfUrl && !isBusy ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: 'auto',
                        width: '100%',
                        height: 'auto',
                        backgroundColor: '#525659',
                        padding: '5px',
                    }}
                >
                    <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
                        {Array.from({ length: numPages }, (_, index) => (
                            <Page key={`page_${index + 1}`} pageNumber={index + 1} width={pdfDimension.width * 1.5} />
                        ))}
                    </Document>
                </div>
            ) : (
                <div css={loaderStyles}>
                    <div
                        css={css`
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            flex-direction: column;
                        `}
                    >
                        <img src="/images/loading.gif" alt="loader" />

                        <div
                            css={css`
                                text-align: center;
                            `}
                        >
                            Generating Template PDF ...
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
});

export default TemplatePreview;
