import * as ActionTypes from '../actions/ActionTypes';
import ID from '../../_helpers/uuid';
import { payroll, evv } from './defaultFormElements';

const initialState = {
    defaultSchema: {
        title: 'A registration form',
        description: 'A simple form example.',
        type: 'object',
        required: true,
        properties: {
            firstName: {
                type: 'string',
                title: 'First name',
                default: 'Chuck',
            },
            lastName: {
                type: 'string',
                title: 'Last name',
            },
        },
    },
    formElements: [],
    defaultEvvFormElements: [
        {
            fieldName: 'Header Text',
            id: ID.uuid(),
            instruction: 'Header Text',
            key: 'Header',
            type: 'textbox',
            required: true,
        },
        {
            data: [],
            fieldName: 'Services',
            id: ID.uuid(),
            instruction: 'Add Services from edit',
            key: 'ServiceRadio',
            type: 'radio',
            required: true,
        },
        {
            fieldName: 'Signature',
            id: ID.uuid(),
            key: 'Signature',
            type: 'signature',
            required: true,
        },
    ],
    ...payroll(),
    ...evv(),
};

export default function FormData(state = initialState, action) {
    let tempForm, index;
    switch (action.type) {
        case ActionTypes.REORDER_FORM:
            return { ...state, formElements: [...action.payload] };
        case ActionTypes.ADD_FORM_DATA:
            index = state.formElements.findIndex((form) => form.id === action.payload.id);
            if (index !== -1) {
                tempForm = [...state.formElements];
                tempForm[index] = action.payload;
                return { ...state, formElements: tempForm };
            }
            return { ...state, formElements: [...state.formElements, action.payload] };
        case ActionTypes.ADD_FORM_ELEMENT_OPTION:
            if (action?.payload?.isClone) {
                tempForm = [...(state.formElements || [])].map((element) => {
                    let _element = { ...element };
                    const elementId = element?._id || element?.id;
                    if (elementId && elementId === action.payload?.config?.parentFormElement?.id) {
                        const tempOption = { ...action.payload };
                        delete tempOption.isClone;
                        delete tempOption.isDragging;
                        _element.data = [...(_element?.data || []), tempOption];
                    }
                    return _element;
                });
            } else {
                tempForm = [...(state.formElements || [])]
                    .map((element) => {
                        let _element = { ...element };
                        if (element?.key === 'Checkbox' || element?.key === 'Select' || element?.key === 'Radio') {
                            _element.data = (_element?.data || []).map((option) =>
                                option?.value && option?.value === action.payload?.value ? action.payload : option
                            );
                            if (_element.data.length === 0) {
                                return null;
                            }
                        }
                        return _element;
                    })
                    .filter((x) => x);
            }
            return { ...state, formElements: tempForm };
        case ActionTypes.DELETE_FORM_ELEMENT:
            tempForm = [...state.formElements];
            index = tempForm.findIndex((form) => form.id === action.payload);
            tempForm.splice(index, 1);
            return { ...state, formElements: tempForm };
        case ActionTypes.DELETE_FORM_ELEMENT_OPTION:
            tempForm = [...(state.formElements || [])]
                .map((element) => {
                    let _element = { ...element };
                    if (element?.key === 'Checkbox' || element?.key === 'Select' || element?.key === 'Radio') {
                        _element.data = (_element?.data || []).filter((option) => option?.value !== action.payload);
                        if (_element.data.length === 0) {
                            return null;
                        }
                    }
                    return _element;
                })
                .filter((x) => x);
            return { ...state, formElements: tempForm };
        case ActionTypes.REMOVE_fORM:
            return { ...state, formElements: [] };
        case ActionTypes.SET_EVV_DEFAULT:
            return { ...state, formElements: state.defaultEvvFormElements };
        case ActionTypes.SET_PTO_DEFAULT:
            return { ...state, formElements: state.defaultPtoFormElements };
        case ActionTypes.SET_ZEAL_EMPLOYEE_DEFAULT:
            return { ...state, formElements: state.defaultZealEmployeeFormElements };
        case ActionTypes.SET_VISIT_BACKUP_FORM_DEFAULT:
            return { ...state, formElements: state.defaultVisitBackupFormElements };
        default:
            return state;
    }
}
