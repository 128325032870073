import React from 'react';
import { MdDelete } from 'react-icons/md';
import EditElement from '../EditElement';
import { Modal } from 'react-bootstrap';
import FormPicker from '../FormPicker';
import { getGenericFieldData } from './utils';
import { FloatingContainer, MappedFieldDiv, StyledButton } from './styles';

const MappedFields = ({ currentPage, formElements, addFormDataElement, removeFormDataElement }) => {
    const [showFormElements, setShowFormElements] = React.useState(false);
    const handleOnEdit = ({ field }) => {
        addFormDataElement(field);
        setShowFormElements(false);
    };
    return (
        <div className="w-100 d-flex justify-content-center items-center py-4">
            <FloatingContainer>
                <StyledButton onClick={() => setShowFormElements(true)}>
                    <div>+</div>
                    <div>Add Form Element</div>
                </StyledButton>
            </FloatingContainer>
            <MappedFieldDiv>
                <h2 className="font-bold mb-4  border-bottom border-2 border-dark">Mapped Fields From PDF</h2>

                <Modal show={showFormElements} onHide={() => setShowFormElements(false)}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <FormPicker
                            openFormBuilder
                            smartpdfAllowedElements={['InputField', 'Signature', 'Checkbox', 'Radio', 'Select']}
                            handleOnEdit={handleOnEdit}
                            configData={{
                                data: getGenericFieldData(),
                                page: currentPage,
                            }}
                        />
                    </Modal.Body>
                </Modal>
                <div className="w-100">
                    {(formElements || []).map((element, idx) => {
                        return (
                            <div
                                key={idx}
                                className="bg-white rounded  p-2
                                d-flex flex-row justify-content-around
                                "
                            >
                                <div className="flex-grow-1 border-bottom border-1 border-dark">
                                    <span>
                                        {idx + 1}. {element.fieldName}
                                    </span>
                                    <span className="ml-4 badge badge-warning">{element?.key || ''}</span>
                                </div>
                                <div className="ml-2 py-2 d-flex">
                                    <div>
                                        <EditElement
                                            element={element}
                                            onEdit={(field) => {
                                                handleOnEdit({ field });
                                            }}
                                            genericCheckboxOrRadio={getGenericFieldData(currentPage).checkboxOrRadio}
                                        />
                                    </div>
                                    <MdDelete
                                        color="red"
                                        className="mt-2 ml-2 cursor-pointer"
                                        size={18}
                                        onClick={() => removeFormDataElement(element?._id || element?.id)}
                                        index={idx}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </MappedFieldDiv>
        </div>
    );
};

export default MappedFields;
