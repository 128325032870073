import React, { useState } from 'react';

import { css } from 'twin.macro';
import { toast } from 'react-toastify';
import { FaDownload } from 'react-icons/fa';
import { getFileNameFromUrl } from '../../_helpers';
import { saveAs } from 'file-saver';
import { VscLoading } from 'react-icons/vsc';

function DownloadBtn({ url, fileName }) {
    const [isBusy, setIsBusy] = useState(false);
    const downloadFile = async () => {
        if (isBusy) {
            return;
        }
        try {
            setIsBusy(true);
            const _fileName = fileName || getFileNameFromUrl(url);
            if (!url || !_fileName) {
                throw new Error('File URL and filename are required');
            }

            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Failed to download file');
            }

            const blob = await response.blob();
            saveAs(blob, _fileName);
            toast.info('File downloaded successfully');
        } catch (error) {
            console.error('Download error:', error);
        } finally {
            setIsBusy(false);
        }
    };
    return (
        <button
            css={css`
                position: absolute;
                top: 0;
                right: 40px;
                padding: 1rem;
                border: none;
                background: #e89842;
                color: white;
                text-align: center;
                border-radius: 1000px;
                display: flex;
                opacity: 0;
            `}
            className="shareBtn"
            onClick={downloadFile}
            disable={isBusy}
        >
            {isBusy ? <VscLoading fontSize={18} className="spin" /> : <FaDownload fontSize={18} />}
        </button>
    );
}

export default DownloadBtn;
