import { css } from 'twin.macro';
import loaderStyles from '../../../../_elements/loader';

export const PdfLoader = () => (
    <div
        css={css`
            height: 70vh;
        `}
    >
        <div css={loaderStyles}>
            <div
                css={css`
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    flex-direction: column;
                `}
            >
                <img src="/images/loading.gif" alt="loader" />

                <div
                    css={css`
                        text-align: center;
                    `}
                >
                    Loading Template PDF ...
                </div>
            </div>
        </div>
    </div>
);
