import * as ActionTypes from '../actions/ActionTypes';
import ID from '../../_helpers/uuid';
import { getUniqueObjects } from '../../_helpers';

export default function Teams(
    state = {
        isLoading: false,
        message: null,
        teams: [],
        teamMembers: [],
        SearchUser: {},
        teamsTen: [],
        totalTeams: 9e9,
    },
    action
) {
    let tempTeam;
    switch (action.type) {
        case ActionTypes.SET_TEAMS:
            const newTeamList = getUniqueObjects(state.teams, action.payload?.teams || []);
            return {
                ...state,
                teams: newTeamList,
                totalTeams: action.payload?.totalTeams || newTeamList.length,
            };
        case ActionTypes.SET_TEN_TEAMS:
            return { ...state, teamsTen: action.payload.results, totalTeams: action.payload.totalResults };
        case ActionTypes.SET_NEW_TEAM:
            tempTeam = [...state.teams];
            const newTeamIndex = tempTeam.findIndex((team) => (team || {}).id === action.payload.id);
            if (newTeamIndex !== -1) {
                tempTeam[newTeamIndex] = action.payload;
                return { ...state, teams: tempTeam };
            }
            return { ...state, teams: [action.payload, ...tempTeam], totalTeams: state.totalTeams + 1 };

        case ActionTypes.SET_EDIT_TEAM:
            tempTeam = [...state.teams];
            const indx = tempTeam.findIndex((team) => (team || {}).id === action.payload.id);
            if (indx !== -1) {
                tempTeam[indx].name = action.payload.name;
                tempTeam[indx].avatar = action.payload.avatar;
                return { ...state, teams: tempTeam };
            }
            return { ...state, teams: [action.payload, ...tempTeam], totalTeams: state.totalTeams + 1 };

        case ActionTypes.SET_TEAM_OWNERSHIP:
            tempTeam = [...state.teams];
            const ownerShipTeamIndex = tempTeam.findIndex((team) => (team || {}).id === action.payload.id);
            if (ownerShipTeamIndex !== -1) {
                tempTeam[ownerShipTeamIndex].admin = action.payload.admin;
                tempTeam[ownerShipTeamIndex].adminData = action.payload.adminData;
                return { ...state, teams: tempTeam };
            }
            return { ...state, teams: [action.payload, ...tempTeam] };

        // case ActionTypes.SET_PUBLIC_TEAMS:
        // 	return { ...state, publicTeams: action.payload };

        // case ActionTypes.REMOVE_PUBLIC_TEAMS:
        // 	return { ...state, publicTeams: [] };

        case ActionTypes.REMOVE_TEAM:
            tempTeam = [...state.teams];
            const index = tempTeam.findIndex((team) => team.id === action.payload);
            if (index !== -1) {
                tempTeam.splice(index, 1);
                return { ...state, teams: tempTeam };
            }
            return state;
        case ActionTypes.SET_TEAM_MEMBER:
            return { ...state, teamMembers: [...action.payload] };
        case ActionTypes.SET_NEW_TEAM_MEMBER:
            return { ...state, teamMembers: [action.payload, ...state.teamMembers] };
        case ActionTypes.SET_MANAGER:
            const newTeamMembers = Object.assign([], state.teamMembers);
            newTeamMembers.forEach((member) => {
                if ((member?.email && member?.email === action.payload?.email) || member?._id === action.payload?._id) {
                    member.team = [];
                    member.team.push({
                        role: 'teamManager',
                        _id: ID.uuid(),
                        team: action.payload?.teamId,
                    });
                }
            });
            return { ...state, teamMembers: newTeamMembers };
        case ActionTypes.SET_TEAM_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.CLEAR_TEAM_LOADING:
            return {
                ...state,
                isLoading: false,
            };

        default:
            return state;
    }
}
