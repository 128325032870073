import { useEffect, useState } from 'react';

// import PropTypes from 'prop-types';

import FormPicker from './FormPicker';
import DynamicPreview from './DynamicPreview';

import { useDispatch } from 'react-redux';
import { fetchWrapper } from '../../../_helpers';
import { CopyFormStyled } from './styles/CopyFormStyles';
import { getTeamIdFromURL, getChannelIdFromSettingsURL } from '../../../_helpers';

import NavigationBar from '../reusableComponents/NavigationBar';
import Loader from '../containers/Loader';
//icons

export const CopyForm = ({ match }) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState(null);
    const teamId = getTeamIdFromURL();
    const channelId = getChannelIdFromSettingsURL();

    const [loading, setLoading] = useState(false);
    useEffect(() => {
        dispatch({ type: 'REMOVE_fORM' });
        setLoading(true);
        fetchWrapper.get(`/forms/${match.params.id}`).then((res) => {
            setFormData(res);
            for (let idx in res.data) {
                let newFormData = Object.assign({}, res.data[idx]);
                newFormData.id = res.data[idx]._id;

                dispatch({ type: 'ADD_FORM_DATA', payload: newFormData });
            }
            setLoading(false);
        });
    }, [dispatch, setFormData, match.params.id]);

    useEffect(() => {
        dispatch({ type: 'REMOVE_fORM' });
    }, [dispatch]);

    return (
        <CopyFormStyled>
            <div className="d-flex justify-content-between mb-4">
                <NavigationBar mainTabName="Copy" subtab={formData?.name} />
            </div>
            {loading ? (
                <Loader size="sm" />
            ) : (
                <div className="d-flex flex-row position-relative">
                    <div className="w-100 d-flex justify-content-center">
                        <DynamicPreview
                            placeholderName={formData?.name ? `Copy of ${formData?.name}` : ''}
                            formformType={formData?.type}
                            formLocationOptional={formData?.isLocationOptional}
                            teamId={teamId}
                            channelId={channelId}
                            formVisibleTo={formData?.visibleTo}
                        />
                    </div>
                    <FormPicker openFormBuilder />
                </div>
            )}
        </CopyFormStyled>
    );
};

CopyForm.propTypes = {};

export default CopyForm;
