//core imports
import { useState, Fragment, useEffect } from 'react';
import { Modal, Button, Dropdown, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FaUserPlus } from 'react-icons/fa';
import { css } from 'twin.macro';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

//redux stuff
import { connect } from 'react-redux';
import { AssignNewMemberToTeam, AssignManagerToTeam } from '../../../_redux/actions/ActionOrganization';
import { startLoading, stopLoading } from '../../../_redux/actions/ActionAuthentication';

//componentss
import ReactSelectComponent from '../../Admin/users/ReactSelectComponent';
import { InviteToTeamContent } from './InviteToTeamContent';

//styles
import loaderStyles from '../../../_elements/loader';
import { formLabel } from '../../../_elements/formLabel';
import formInput from '../../../_elements/formInput';
import { getOtherUserData } from '../../../_redux/actions/ActionUser';
import { getContactErrors, isMemberPresent } from './AppPeopleModal';

function InviteToTeam({
    teamMembers,
    AssignUserToTeam,
    AssignNewMemberToTeam,
    AssignManagerToTeam,
    teamId,
    orgId,
    getOtherUserData,
    startLoading,
    ui,
    stopLoading,
    type,
    inviteManager = false,
}) {
    const [show, setShow] = useState(false);
    const [selectedOption, setSelectedOption] = useState();
    const [addByEmail, setAddByEmail] = useState(false);
    const [user, setUser] = useState(null);
    const [step, setStep] = useState('1');
    const [userName, setUserName] = useState(null);
    const [emailId, setEmailId] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isPhone, setIsPhone] = useState(false);
    const [errors, setErrors] = useState({});

    //Get the selected options values in user state
    useEffect(() => {
        if (selectedOption && !addByEmail) {
            const fectUserData = async () => {
                let user = await getOtherUserData(selectedOption.value);
                return setUser(user);
            };
            fectUserData();
        }
    }, [selectedOption]);

    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => {
        setShow(true);
        setAddByEmail(false);
        setUser(null);
        setSelectedOption({});
        setStep('1');
        setUserName(null);
        setEmailId(null);
        setPhoneNumber('');
    };

    const handleInvite = async () => {
        try {
            startLoading();
            let tempData;
            let response;

            if (user) {
                tempData = user;
            } else if (userName) {
                tempData = {
                    name: userName,
                    phone: isPhone ? `+${phoneNumber || ''}` : undefined,
                    email: !isPhone ? emailId : undefined,
                };
            } else {
                return;
            }

            if (inviteManager) {
                const isUserPresentInTeam = isMemberPresent({
                    members: teamMembers,
                    email: tempData?.email,
                    phone: tempData?.phone,
                    _id: tempData?._id || tempData?.id,
                });
                response = await AssignManagerToTeam(orgId, teamId, tempData, isUserPresentInTeam);
            } else {
                response = await AssignNewMemberToTeam(orgId, teamId, tempData);
            }

            if (response) {
                setShow(false);
                setUser(null);
                setSelectedOption(null);
            }
        } catch (err) {
            console.error(err);
        } finally {
            stopLoading();
        }
    };

    const handleBack = () => {
        setUser(null);
        setStep('1');
    };
    const handleNext = async () => {
        const email = !isPhone ? emailId : '';
        const phone = isPhone ? phoneNumber : '';
        const name = userName;

        const isPresent = isMemberPresent({
            members: teamMembers,
            email,
            phone,
            user,
        });

        if (isPresent && !inviteManager) {
            return toast.error('User is already in team');
        }
        if (!addByEmail) {
            return setStep('2');
        }
        const newErrors = getContactErrors({ name, email, phone, isPhone });

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        return setStep('2');
    };

    return (
        <Fragment>
            {type === 'admin' ? (
                <Dropdown.Item onClick={handleShow} style={{ borderRadius: '4px' }}>
                    {inviteManager ? 'Invite Manager' : <FaUserPlus />}
                </Dropdown.Item>
            ) : (
                <Dropdown.Item onClick={handleShow} style={{ borderRadius: '4px' }}>
                    {inviteManager ? 'Invite Manager' : ' Invite to team'}
                </Dropdown.Item>
            )}

            {show && (
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title className="modal-headers">
                            Invite {inviteManager ? 'Manager' : 'People'} in This Team
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {ui.loading && (
                            <div css={loaderStyles}>
                                <img src="/images/loading.gif" alt="loader" />
                            </div>
                        )}
                        {step === '1' ? (
                            addByEmail ? (
                                <Fragment>
                                    <div className="d-flex flex-row align-items-center justify-content-center gap-2">
                                        <label className="cursor-pointer">
                                            <input
                                                type="radio"
                                                name="contactType"
                                                checked={isPhone}
                                                onChange={() => setIsPhone(true)}
                                                className="mr-1"
                                            />
                                            Phone
                                        </label>
                                        <label className="cursor-pointer ml-2">
                                            <input
                                                type="radio"
                                                name="contactType"
                                                checked={!isPhone}
                                                onChange={() => setIsPhone(false)}
                                                className="mr-1"
                                            />
                                            Email
                                        </label>
                                    </div>
                                    {isPhone ? (
                                        <Form.Group>
                                            <Form.Label css={formLabel}>Phone</Form.Label>
                                            <PhoneInput
                                                country={'us'}
                                                value={phoneNumber}
                                                onChange={(phone) => {
                                                    setPhoneNumber(phone);
                                                    setErrors((prev) => ({ ...prev, phone: '' }));
                                                }}
                                                css={css`
                                                    &.react-tel-input input {
                                                        width: 100%;
                                                    }
                                                `}
                                            />
                                            <Form.Text className="text-danger">{errors.phone}</Form.Text>
                                        </Form.Group>
                                    ) : (
                                        <Form.Group>
                                            <Form.Label css={formLabel}>Email address</Form.Label>
                                            <Form.Control
                                                css={formInput}
                                                type="email"
                                                isInvalid={!!errors.email}
                                                onChange={(e) => {
                                                    setEmailId(e.target.value);
                                                    setErrors((prev) => ({ ...prev, email: '' }));
                                                }}
                                                placeholder="Enter email"
                                                value={emailId || ''}
                                            />
                                            <Form.Text className="text-danger">{errors.email}</Form.Text>
                                        </Form.Group>
                                    )}
                                    <Form.Group>
                                        <Form.Label css={formLabel}>Name</Form.Label>
                                        <Form.Control
                                            css={formInput}
                                            type="text"
                                            isInvalid={!!errors.name}
                                            onChange={(e) => {
                                                setUserName(e.target.value);
                                                setErrors((prev) => ({ ...prev, name: '' }));
                                            }}
                                            placeholder="Enter name"
                                            value={userName || ''}
                                        />
                                        <Form.Text className="text-danger">{errors.name}</Form.Text>
                                    </Form.Group>
                                    <div>
                                        Add by
                                        <button
                                            className="btn btn-link ml-2 "
                                            onClick={() => {
                                                setAddByEmail(false);
                                                setEmailId(null);
                                                setUserName(null);
                                                setPhoneNumber(null);
                                            }}
                                        >
                                            Name
                                        </button>
                                        ?
                                    </div>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label css={formLabel}>Name</Form.Label>
                                        <ReactSelectComponent
                                            css={formInput}
                                            selectedOption={selectedOption}
                                            setSelectedOption={setSelectedOption}
                                            placeholder="Search user"
                                            assignedTo={selectedOption && selectedOption.value}
                                        />
                                    </Form.Group>
                                    <div>
                                        User not in the system? Add by{' '}
                                        <button
                                            className="btn btn-link "
                                            onClick={() => {
                                                setUser(null);
                                                setAddByEmail(true);
                                            }}
                                        >
                                            Email or Phone
                                        </button>
                                    </div>
                                </Fragment>
                            )
                        ) : (
                            <Fragment>
                                {user ? (
                                    <InviteToTeamContent teamId={null} setShow={setShow} user={user} />
                                ) : (
                                    <Fragment>
                                        <Form.Group>
                                            <Form.Label css={formLabel}>Phone Number</Form.Label>
                                            <Form.Control
                                                css={formInput}
                                                type="text"
                                                defaultValue={isPhone ? `+${phoneNumber}` : ''}
                                                disabled
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label css={formLabel}>Email Address</Form.Label>
                                            <Form.Control
                                                css={formInput}
                                                type="email"
                                                defaultValue={!isPhone ? emailId : ''}
                                                disabled
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label css={formLabel}>Name</Form.Label>
                                            <Form.Control
                                                css={formInput}
                                                type="text"
                                                defaultValue={userName}
                                                disabled
                                            />
                                        </Form.Group>
                                    </Fragment>
                                )}
                            </Fragment>
                        )}
                    </Modal.Body>

                    <Modal.Footer>
                        {step === '1' ? (
                            <div className="w-100 d-flex justify-content-between">
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={handleNext}>
                                    Next
                                </Button>
                            </div>
                        ) : (
                            <div className="w-100 d-flex justify-content-between">
                                <Button variant="secondary" onClick={handleBack}>
                                    Back
                                </Button>
                                <Button variant="primary" onClick={handleInvite}>
                                    Invite
                                </Button>
                            </div>
                        )}
                    </Modal.Footer>
                </Modal>
            )}
        </Fragment>
    );
}
const mapStateToProps = (state) => ({
    ui: state.ui,
    teamMembers: state.teams.teamMembers,
});

export default connect(mapStateToProps, {
    getOtherUserData,
    startLoading,
    stopLoading,
    AssignNewMemberToTeam,
    AssignManagerToTeam,
})(InviteToTeam);
