// core imports
import { css } from 'twin.macro';
import { useState, Fragment, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

//componentss
import ReactSelectComponent from '../../Admin/users/ReactSelectComponent';
import { InviteToTeamContent } from './InviteToTeamContent';

import loaderStyles from '../../../_elements/loader';
import { formLabel } from '../../../_elements/formLabel';
import formInput from '../../../_elements/formInput';

import { fetchWrapper, isValidEmail, isValidPhone } from '../../../_helpers';
import { checkPermission } from '../../../_helpers/roles/check-roles';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export const isMemberPresent = ({ members, email, phone, user }) => {
    return (members || []).find((member) => {
        if (member && member?._id) {
            const _user = user || {};
            const _userId = _user?._id || _user?.id;
            const _inputEmail = _user?.email || email;
            const _inputPhone = _user?.phone || (phone ? `+${phone}` : phone);

            return _userId
                ? member._id === _userId
                : (member.email && member.email === _inputEmail) || (member.phone && member.phone === _inputPhone);
        }
        return false;
    });
};

export const getContactErrors = ({ name, email, phone, isPhone }) => {
    let _newErrors = {};

    if (!name) {
        _newErrors.name = 'Name is required';
    }
    if (isPhone) {
        if (!isValidPhone(phone)) {
            _newErrors.phone = 'Phone number is invalid';
        }
        if (!phone) {
            _newErrors.phone = 'Phone number is required';
        }
    } else if (!isPhone) {
        if (!isValidEmail(email)) {
            _newErrors.email = 'Email is invalid';
        }
        if (!email) {
            _newErrors.email = 'Email is required';
        }
    }
    return _newErrors;
};

function AppPeopleModal({
    show,
    handleClose,
    handleShow,
    role,
    roomId,
    channelMembers,
    ui,
    startLoading,
    stopLoading,
    getOtherUserData,
    getChannelMembers,
}) {
    const [userName, setUserName] = useState(null);
    const [emailId, setEmailId] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isPhone, setIsPhone] = useState(false);
    const [errors, setErrors] = useState({});
    const [selectedOption, setSelectedOption] = useState();
    const [addByEmail, setAddByEmail] = useState(false);
    const [step, setStep] = useState('userInfo');
    const [user, setUser] = useState(null);
    const [assignedRole, setAssignedRole] = useState('user');

    //Get the selected options values in user state
    useEffect(() => {
        if (selectedOption && !addByEmail) {
            const fectUserData = async () => {
                let user = await getOtherUserData(selectedOption.value);
                return setUser(user);
            };
            fectUserData();
        }
    }, [selectedOption]);

    const assignRoomToUser = async (newUser, chatroomId) => {
        try {
            const response = await fetchWrapper.post(`chatrooms/${chatroomId}/invite`, newUser);
            toast.success(response.message);
            getChannelMembers(chatroomId);

            stopLoading();
            handleClose();
        } catch (e) {
            stopLoading();
            toast.error('Error while inviting');
        }
    };

    const handleAdd = () => {
        startLoading();

        if (user) {
            const tempUser = {
                name: user.name || '',
                email: user.email,
                role: assignedRole,
                userId: user?._id || user?.id,
            };
            assignRoomToUser(tempUser, roomId);
        } else if (userName) {
            const tempData = {
                name: userName,
                email: emailId,
                role: assignedRole,
            };
            if (isPhone) {
                tempData.phone = `+${phoneNumber}`;
                delete tempData.email;
            }
            assignRoomToUser(tempData, roomId);
        } else {
            stopLoading();
            console.log('no data no user', userName, emailId);
        }
    };
    const handleBack = () => {
        setStep('userInfo');
        setUser(null);
    };

    const handleNext = async () => {
        if (!addByEmail) {
            return setStep('roleInfo');
        }
        const email = !isPhone ? emailId : '';
        const phone = isPhone ? phoneNumber : '';
        const name = userName;

        let newErrors = getContactErrors({ name, email, phone, isPhone });

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        const isPresent = isMemberPresent({ members: channelMembers, email, phone, user });
        if (isPresent) {
            return toast.error('User is already in room');
        } else {
            return setStep('roleInfo');
        }
    };

    const handleRoleChange = (e) => {
        setAssignedRole(e.target.value);
    };
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className="modal-headers">Add People To Channel</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {ui.loading && (
                    <div css={loaderStyles}>
                        <img src="/images/loading.gif" alt="loader" />
                    </div>
                )}
                {step === 'userInfo' ? (
                    <Fragment>
                        {addByEmail ? (
                            <Fragment>
                                <div className="d-flex flex-row align-items-center justify-content-center gap-2">
                                    <label className="cursor-pointer">
                                        <input
                                            type="radio"
                                            name="contactType"
                                            checked={isPhone}
                                            onChange={() => setIsPhone(true)}
                                            className="mr-1"
                                        />
                                        Phone
                                    </label>
                                    <label className="cursor-pointer ml-2">
                                        <input
                                            type="radio"
                                            name="contactType"
                                            checked={!isPhone}
                                            onChange={() => setIsPhone(false)}
                                            className="mr-1"
                                        />
                                        Email
                                    </label>
                                </div>
                                {isPhone ? (
                                    <Form.Group>
                                        <Form.Label css={formLabel}>Phone</Form.Label>
                                        <PhoneInput
                                            country={'us'}
                                            value={phoneNumber}
                                            onChange={(phone) => {
                                                setPhoneNumber(phone);
                                                setErrors((prev) => ({ ...prev, phone: '' }));
                                            }}
                                            css={css`
                                                &.react-tel-input input {
                                                    width: 100%;
                                                }
                                            `}
                                        />
                                        <Form.Text className="text-danger">{errors.phone}</Form.Text>
                                    </Form.Group>
                                ) : (
                                    <Form.Group>
                                        <Form.Label css={formLabel}>Email address</Form.Label>
                                        <Form.Control
                                            css={formInput}
                                            type="email"
                                            isInvalid={!!errors.email}
                                            onChange={(e) => {
                                                setEmailId(e.target.value);
                                                setErrors((prev) => ({ ...prev, email: '' }));
                                            }}
                                            placeholder="Enter email"
                                            value={emailId || ''}
                                        />
                                        <Form.Text className="text-danger">{errors.email}</Form.Text>
                                    </Form.Group>
                                )}
                                <Form.Group>
                                    <Form.Label css={formLabel}>Name</Form.Label>
                                    <Form.Control
                                        css={formInput}
                                        type="text"
                                        isInvalid={!!errors.name}
                                        onChange={(e) => {
                                            setUserName(e.target.value);
                                            setErrors((prev) => ({ ...prev, name: '' }));
                                        }}
                                        placeholder="Enter name"
                                        value={userName || ''}
                                    />
                                    <Form.Text className="text-danger">{errors.name}</Form.Text>
                                </Form.Group>
                                <div
                                    css={css`
                                        display: flex;
                                        align-items: center;
                                    `}
                                >
                                    Add by
                                    <button
                                        className="btn btn-link "
                                        css={css`
                                            font-size: 1.8rem;
                                            padding: 0;
                                            margin-left: 2px;
                                        `}
                                        onClick={() => {
                                            setPhoneNumber(null);
                                            setEmailId(null);
                                            setUserName(null);
                                            setAddByEmail(false);
                                        }}
                                    >
                                        Name
                                    </button>
                                    ?
                                </div>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label css={formLabel}>Name</Form.Label>
                                    <ReactSelectComponent
                                        selectedOption={selectedOption}
                                        setSelectedOption={setSelectedOption}
                                        placeholder="Search user"
                                        assignedTo={selectedOption && selectedOption.value}
                                    />
                                </Form.Group>
                                <div>
                                    User not in the system? Add by{' '}
                                    <button
                                        className="btn btn-link "
                                        onClick={() => {
                                            setAddByEmail(true);
                                            setUser(null);
                                        }}
                                    >
                                        Email or Phone
                                    </button>
                                </div>
                            </Fragment>
                        )}
                    </Fragment>
                ) : (
                    <Fragment>
                        {console.log(role, 'ROLE')}
                        {console.log(checkPermission(role, 'assign:channalRole'))}
                        {
                            // Show Roles setting for specific roled users only
                            checkPermission(role, 'assign:channalRole') && (
                                <div className="mb-4">
                                    <h2>Role</h2>

                                    <div onChange={handleRoleChange}>
                                        <label className="mx-2">
                                            <input type="radio" value="user" name="role" defaultChecked /> User
                                        </label>
                                        <label className="mx-2">
                                            <input type="radio" value="supervisor" name="role" /> Supervisor
                                        </label>
                                        <label className="mx-2">
                                            <input type="radio" value="doctor" name="role" /> Third Party
                                        </label>
                                        <label className="mx-2">
                                            <input type="radio" value="family" name="role" /> Family
                                        </label>
                                    </div>
                                </div>
                            )
                        }
                        {user ? (
                            <InviteToTeamContent teamId={null} setShow={handleShow} user={user} />
                        ) : (
                            <Fragment>
                                <Form.Group>
                                    <Form.Label css={formLabel}>Phone Number</Form.Label>
                                    <Form.Control
                                        css={formInput}
                                        type="text"
                                        defaultValue={isPhone ? `+${phoneNumber}` : ''}
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label css={formLabel}>Email Address</Form.Label>
                                    <Form.Control
                                        css={formInput}
                                        type="email"
                                        defaultValue={!isPhone ? emailId : ''}
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label css={formLabel}>Name</Form.Label>
                                    <Form.Control css={formInput} type="text" defaultValue={userName} disabled />
                                </Form.Group>
                            </Fragment>
                        )}
                    </Fragment>
                )}
            </Modal.Body>
            <Modal.Footer>
                {step === 'userInfo' ? (
                    <Fragment>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleNext}>
                            Next
                        </Button>
                    </Fragment>
                ) : (
                    <Fragment>
                        <Button variant="secondary" onClick={handleBack}>
                            Back
                        </Button>
                        <Button variant="primary" onClick={handleAdd}>
                            Add
                        </Button>
                    </Fragment>
                )}
            </Modal.Footer>
        </Modal>
    );
}

export default AppPeopleModal;
