import * as Yup from 'yup';
import { PHONE_NUMBER_REGEX } from '../contants';
import { isValidPhone } from './methods';

export const regValidationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    phone: Yup.string(),
    email: Yup.string().email('Email is invalid'),
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .matches(/\d/, 'Password must contain atleast one number')
        .matches(/[a-zA-Z]/, 'Password must contain atleast one letter'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
    policy: Yup.boolean()
        .required('The terms and conditions must be accepted.')
        .oneOf([true], 'The terms and conditions must be accepted.'),
    username: Yup.string()
        .required('Username is requried')
        .min(6, 'Username must be at least 6 characters')
        .matches(
            /^[a-z0-9_]{5,}[a-z0-9]+[0-9]*$/,
            'Invalid Username! it can only contain small alphabets, numbers and "_"'
        ),
    contact: Yup.string().test('contact-required', 'Either phone or email is required !', function () {
        const { phone, email } = this.parent;
        return phone || email ? true : false;
    }),
});

export const createUserValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    username: Yup.string()
        .required('Username is requried')
        .min(6, 'Username must be at least 6 characters')
        .matches(
            /^[a-z0-9_]{5,}[a-z0-9]+[0-9]*$/,
            'Invalid Username! it can only contain small alphabets, numbers and "_"'
        ),
    email: Yup.string().email('Email is invalid'),
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .matches(/\d/, 'Password must contain atleast one number')
        .matches(/[a-zA-Z]/, 'Password must contain atleast one letter'),
    phone: Yup.string(),
    contact: Yup.string().test('contact-required', 'Either phone or email is required !', function () {
        const { phone, email } = this.parent;
        return phone || email ? true : false;
    }),
});

export const logValidationSchema = Yup.object().shape({
    contact: Yup.string()
        .test('is-email-or-phone', 'Email or Phone is invalid', (value) => {
            const isEmailValid = Yup.string().email().isValidSync(value);
            const isPhoneValid = isValidPhone(value);
            return isEmailValid || isPhoneValid;
        })
        .required('Email or phone is required'),
    password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
});

export const reportIssueValidationSchema = Yup.object().shape({
    email: Yup.string().email('Email is invalid').required('Email is required'),
    name: Yup.string().required('Name is required'),
    message: Yup.string().required('Message is required'),
});
export const onlyEmailValidation = Yup.object().shape({
    email: Yup.string().email('Email is invalid').required('Email is required'),
});

export const emailOrPhoneValidation = Yup.object().shape({
    contact: Yup.string()
        .test('is-email-or-phone', 'Email or Phone is invalid', (value) => {
            const isEmailValid = Yup.string().email().isValidSync(value);
            const isPhoneValid = isValidPhone(value);
            return isEmailValid || isPhoneValid;
        })
        .required('Email or phone is required'),
});
export const resetPasswordValidation = Yup.object().shape({
    token: Yup.string().required('token is required'),
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .matches(/\d/, 'Password must contain atleast one number')
        .matches(/[a-zA-Z]/, 'Password must contain atleast one letter'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Confirm Passwords must match with Password')
        .required('Confirm Password is required'),
});
export const forceResetPasswordValidation = Yup.object().shape({
    currentPassword: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .matches(/\d/, 'Password must contain atleast one number')
        .matches(/[a-zA-Z]/, 'Password must contain atleast one letter'),
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .matches(/\d/, 'Password must contain atleast one number')
        .matches(/[a-zA-Z]/, 'Password must contain atleast one letter')
        .notOneOf([Yup.ref('currentPassword'), null], 'New Password cannot be same as Old Password'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Confirm Passwords must match with New Password')
        .required('Confirm Password is required'),
});

export const createTeamValidation = Yup.object().shape({
    name: Yup.string().required("Team's name is requried"),
});

export const inviteToRoomValidation = Yup.object().shape({
    email: Yup.string().email('Email is invalid'),
    phone: Yup.string().matches(PHONE_NUMBER_REGEX, 'Phone number is invalid'),
    name: Yup.string().required('Name is requried'),
    contact: Yup.string().test('contact-required', 'Either phone or email is required !', function () {
        const { phone, email } = this.parent;
        return phone || email ? true : false;
    }),
});
export const createChannelValidation = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    subTitle: Yup.string().required('Description is requried'),
});
export const updateValidationSchema = Yup.object().shape({
    // name: Yup.string().when(['email', 'password'], {
    // 	is: (email, password) => !email && !password,
    // 	then: Yup.string().required('At least one field is required'),
    // }),
    // email: Yup.string().when(['name', 'password'], {
    // 	is: (name, password) => !name && !password,
    // 	then: Yup.string().required('At least one field is required').email('Email is invalid'),
    // }),
    name: Yup.string().required('Name is Required'),
    address: Yup.string().required('Address is Required'),
    // phone: Yup.string().required('Phone is required'),
    // password: Yup.string().when(['name', 'email'], {
    // 	is: (name, email) => !name && !email,
    // 	then: Yup.string()
    // 		.required('At least one field is required')
    // 		.min(8, 'Password must be at least 8 characters')
    // 		.matches(
    // 			/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
    // 			'Password must contaion atleast one letter and one number'
    // 		),
    // }),
});

export const userNameValidation = Yup.object().shape({
    userName: Yup.string()
        .required('Username is requried')
        .min(6, 'Username must be at least 6 characters')
        .matches(
            /^[a-z0-9_]{5,}[a-z0-9]+[0-9]*$/,
            'Invalid Username! it can only contain small alphabets, numbers and "_"'
        ),
});

export const phoneValidation = Yup.object().shape({
    phone: Yup.string().required('Phone number is requried'),
});

export const clientRegistrationValidation = Yup.object().shape({
    // 'InputField-02': Yup.string()
    // 	.required('Client Identifier is requried')
    // 	.matches(/^[0-9]*$/, 'Client Identifier must only contain numbers.'),
    // 'InputField-03': Yup.string()
    // 	.required('ClientMedicaidID is requried')
    // 	.matches(/^[0-9]*$/, 'ClientMedicaidID must only contain numbers.'),
    // 'InputField-04': Yup.string()
    // 	.required('ClientCustomID is requried')
    // 	.matches(/^[0-9]*$/, 'ClientCustomID must only contain numbers.'),
    // 'InputField-05': Yup.string()
    // 	.required('ClientFirstName is requried')
    // 	.matches(/^[a-zA-Z ]*$/, 'Client FirstName must only contain albhabets.'),
    // 'InputField-06': Yup.string()
    // 	.required('ClientLastName is requried')
    // 	.matches(/^[a-zA-Z ]*$/, 'Client LastName must only contain albhabets.'),
    // 'InputField-10': Yup.string().required('ClientAddressLine1 is requried'),
    // 'InputField-11': Yup.string()
    // 	.required('Country name is requried')
    // 	.matches(/^[a-zA-Z ]*$/, 'Country name must only contain Alphabets.'),
    // 'InputField-12': Yup.string()
    // 	.required('City name is requried')
    // 	.matches(/^[a-zA-Z ]*$/, 'City name must only contain Alphabets..'),
    // 'InputField-13': Yup.string()
    // 	.required('State is requried')
    // 	.matches(
    // 		/^(AL|AK|AZ|AR|CA|CO|CT|DC|DE|FL|GA|HI|ID|IL|IN|IA|KS|KY|LA|ME|MD|MA|MI|MN|MS|MO|MT|NE|NV|NH|NJ|NM|NY|NC|ND|OH|OK|OR|PA|RI|SC|SD|TN|TX|UT|VT|VA|WA|WV|WI|WY|AS|DC|FM|GU|MH|MP|PW|PR|VI)$/,
    // 		'Invalid State'
    // 	),
    // 'InputField-14': Yup.string()
    // 	.required('Zip is requried')
    // 	.matches(/^\d{5}$|^\d{5}\d{4}$/, 'Incorrect ZIP Format'),
});

// export const staffFormValidationSchema = Yup.object().shape({
// 	'InputField-05': Yup.string()
// 		.required('Employee First name is requried')
// 		.matches(, 'Employee First name must only contain albhabets.'),
// 	'InputField-06': Yup.string()
// 		.required('Employee Last name is requried')
// 		.matches(/^[a-zA-Z ]*$/, 'Employee Last name  must only contain albhabets.'),
// 	'Email-07': Yup.string().email('Email is invalid').required(''),
// 	'InputField-02': Yup.string()
// 		.required('Employee Identifier is requried')
// 		.matches(/^[0-9]*$/, 'Employee Identifier must only contain numbers.'),
// 	'InputField-03': Yup.string()
// 		.required('Employee OtherID is requried')
// 		.matches(/^[0-9]*$/, 'Employee OtherID must only contain numbers.'),
// 	'SSNField-04': Yup.string()
// 		.required('SSN is requried')
// 		.matches(/^[0-9]*$/, 'SSN must only contain numbers.'),
// });

export const serviceFormValidationSchema = Yup.object().shape({
    PayerID: Yup.string().required('PayerID is requried'),
    ProgramOffice: Yup.string().required('ProgramOffice is requried'),
    HCPCS: Yup.string().required('HCPCS is required'),
    Description: Yup.string().required('Description is requried'),
});

export const providerValidationSchema = Yup.object().shape({
    agencyName: Yup.string().required('Agency Name is requried'),
    // .matches(/^[a-zA-Z ]*$/, 'Agency name must only contain albhabets.'),
    // businessMedicaidIdentifier: Yup.string()
    // 	.required('Business medicaid Identifier is requried')
    // 	.matches(/^[0-9]*$/, 'Business medicaid  must only contain numbers.'),
    // businessEntityId: Yup.string()
    // 	.required('Business EntityId is requried')
    // 	.matches(/^[0-9]*$/, 'Business EntityId must only contain numbers.'),
    // userName: Yup.string()
    // 	.required('userName is requried')
    // 	.matches(/^[a-zA-Z0-9]*$/, 'User Name must only contain alphabets and Numbers.'),
    // password: Yup.string().required('Password is requried'),
});

export const createEventTypeValidation = Yup.object().shape({
    title: Yup.string().required('Title is requried'),
    description: Yup.string().required('Description is requried'),
});

export const createEventValidation = Yup.object().shape({
    title: Yup.string().required('Title is requried'),
    description: Yup.string().required('Description is requried'),
});
