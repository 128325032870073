import React, { useState, useEffect, Fragment } from 'react';
import { Modal, Button, Col, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { css } from 'twin.macro';
import DateTimePicker from 'react-datetime-picker/dist/DateTimePicker';
import { toast } from 'react-toastify';

const getStartDate = (tasks = []) => {
    const sortedTasks = tasks.sort(
        (a, b) => new Date(a?.latestDatePointer || a?.date) - new Date(b?.latestDatePointer || b?.date)
    );
    const date = sortedTasks.length ? sortedTasks[0]?.latestDatePointer || sortedTasks[0]?.date : new Date();
    return new Date(date);
};

const updateDateInAllEvents = async (tasks, startDate) => {
    if (!tasks) return [];

    const sortedTasks = tasks.sort(
        (a, b) => new Date(a?.latestDatePointer || a?.date) - new Date(b?.latestDatePointer || b?.date)
    );
    const previousStartDate = new Date(
        sortedTasks.length ? sortedTasks[0]?.latestDatePointer || sortedTasks[0]?.date : null
    );
    const newStartDate = new Date(startDate);
    const differenceInMs = newStartDate.getTime() - previousStartDate.getTime();

    return tasks.map((task) => {
        const date = task?.date ? new Date(new Date(task?.date).getTime() + differenceInMs) : undefined;
        const dueDate = task?.dueDate ? new Date(new Date(task?.dueDate).getTime() + differenceInMs) : undefined;
        const latestDatePointer = task?.latestDatePointer
            ? new Date(new Date(task?.latestDatePointer).getTime() + differenceInMs)
            : undefined;
        const latestDueDatePointer = task?.latestDueDatePointer
            ? new Date(new Date(task?.latestDueDatePointer).getTime() + differenceInMs)
            : undefined;

        return {
            _id: task?._id,
            date,
            dueDate,
            latestDatePointer,
            latestDueDatePointer,
        };
    });
};

const AssignStartDateToPackageModal = ({ packageData, onAssignStartDatetoEventCallback }) => {
    const [show, setShow] = useState(false);
    const [startDate, setStartDate] = useState(getStartDate(packageData?.taskData || []));

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const onSubmit = async () => {
        try {
            const updatedTasks = await updateDateInAllEvents(packageData?.taskData, startDate);
            if (onAssignStartDatetoEventCallback) {
                onAssignStartDatetoEventCallback(updatedTasks);
            }
            handleClose();
        } catch (e) {
            console.log(e);
            toast.error(`Error: ${e.message}`);
        }
    };

    useEffect(() => {
        setStartDate(getStartDate(packageData?.taskData || []));
    }, [packageData]);

    return (
        <Fragment>
            <Button onClick={handleShow}>Assign Start Date Globally</Button>
            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header className="h2 font-weight-bold d-flex justify-content-center">
                    Assign Start Date to Plan
                </Modal.Header>
                <Modal.Body className="m-2">
                    <div className="w-90 m-auto">
                        <Form.Row>
                            <Form.Group as={Col} md={12}>
                                <Form.Label>Select Start Date: </Form.Label>
                                <DateTimePicker
                                    onChange={setStartDate}
                                    value={typeof startDate === 'string' ? new Date(startDate) : startDate}
                                    format="MM-dd-y hh:mm a"
                                    disableClock={true}
                                    calendarType={'US'}
                                    clearIcon={null}
                                    className="ml-2"
                                />
                            </Form.Group>
                            <Form.Group as={Col} md={12}>
                                <div
                                    css={css`
                                        padding: 2rem 0 0 0;
                                        display: flex;
                                        width: 100%;
                                        justify-content: space-between;
                                    `}
                                >
                                    <Button variant="secondary" onClick={handleClose}>
                                        Close
                                    </Button>
                                    <Button variant="primary" onClick={onSubmit}>
                                        Assign Start Date
                                    </Button>
                                </div>
                            </Form.Group>
                        </Form.Row>
                    </div>
                </Modal.Body>
            </Modal>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AssignStartDateToPackageModal);
