import React from 'react';
import NotFound from '../components/NotFound/NotFound';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
//redux
import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, roles, user, teams, isLogged, verifyUser, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                const localStorageUser = window.localStorage.getItem('user');
                if (!isLogged || !localStorageUser) {
                    const destinationPath = props.location.pathname;
                    return (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: { referrer: destinationPath },
                            }}
                        />
                    );
                }
                if (user) {
                    let lastPasswordChangedDate = dayjs(user.lastPasswordChangedAt).format('YYYY-MM-DD');
                    let changePasswordDays = 90;
                    let forcePasswordChangeDate = dayjs(lastPasswordChangedDate).add(changePasswordDays, 'day');
                    forcePasswordChangeDate = dayjs(forcePasswordChangeDate).format('YYYY-MM-DD');
                    let todayDate = dayjs().format('YYYY-MM-DD');
                    let isItTimeToForceResetPassword = dayjs(forcePasswordChangeDate).diff(dayjs(todayDate)) <= 0;
                    if (isItTimeToForceResetPassword) {
                        sessionStorage.setItem('previousUrl', window.location.href);
                        return <Redirect to="/force-password-change" />;
                    }

                    if (teams.length && (user.isEmailVerified || user?.isPhoneVerified)) {
                        const allUserOwnedTeam = user.teamList.filter((team) => team.role === 'owner' && team?.team);
                        let userOwnsATeam = Boolean(allUserOwnedTeam.length);
                        if (!userOwnsATeam) {
                            return <Redirect to="/create-your-team" />;
                        }
                    }

                    if (roles && roles.indexOf(user.role) === -1) {
                        return <NotFound />;
                    }
                    const verificationPath = `/verification/${encodeURIComponent(user?.phone || user?.email)}`;
                    if (user.isEmailVerified || user?.isPhoneVerified) {
                        if (
                            props.location.pathname === `${verificationPath}` ||
                            props.location.pathname === `${verificationPath}/`
                        ) {
                            return <Redirect to="/" />;
                        }

                        if (!user.username) {
                            return <Redirect to="/force-username" />;
                        }

                        if (!user.phone) {
                            return <Redirect to="/force-phonenumber" />;
                        }
                        if (verifyUser) {
                            let verified = false;
                            if (props) {
                                let path = props.match.path;
                                let userRole = user.role;
                                if (userRole === 'admin') {
                                    verified = true;
                                }
                                switch (path) {
                                    case '/teams/:teamId/settings':
                                        let teamId = props.match.params.teamId;
                                        let userTeamList = user.teamList;
                                        let team = userTeamList.find((userTeam) => userTeam.team?.id === teamId);
                                        if (team) {
                                            if (['owner', 'teamManager'].includes(team.role)) {
                                                verified = true;
                                            }
                                        }
                                        break;
                                    case '/teams/:teamId/channels/:channelId/settings':
                                        let teamId_ = props.match.params.teamId;
                                        let channelId_ = props.match.params.channelId;
                                        let userTeamList_ = user.teamList;
                                        let userRoomList_ = user.roomList;
                                        let team_ = userTeamList_.find((userTeam) => userTeam.team?.id === teamId_);
                                        let channel_ = userRoomList_.find(
                                            (userChannel) =>
                                                userChannel?.room?.id && userChannel.room?.id === channelId_
                                        );
                                        if (team_) {
                                            if (['owner', 'teamManager'].includes(team_.role)) {
                                                verified = true;
                                            }
                                        }
                                        if (channel_) {
                                            if (['supervisor'].includes(channel_.role)) {
                                                verified = true;
                                            }
                                        }
                                        break;
                                    default:
                                        break;
                                }
                            }
                            if (verified) {
                                return <Component {...props} />;
                            } else {
                                return <Redirect to="/not-found" />;
                            }
                        } else {
                            return <Component {...props} />;
                        }
                    }
                    if (
                        props.location.pathname === `${verificationPath}` ||
                        props.location.pathname === `${verificationPath}/`
                    ) {
                        if (user?.email || user?.phone) {
                            return <Component {...props} />;
                        }
                    }

                    return <Redirect to="/" />;
                }
            }}
        />
    );
};

const AuthRoute = ({ component: Component, isLogged, ...rest }) => (
    <Route {...rest} render={(props) => (!isLogged === true ? <Component {...props} /> : <Redirect to="/" />)} />
);

PrivateRoute.propTypes = {
    isLogged: PropTypes.bool,
};

AuthRoute.propTypes = {
    isLogged: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams,
    isLogged: state.user.isLogged,
});

const ContainerCreater = connect(mapStateToProps);

const FirstConnectedComponent = ContainerCreater(PrivateRoute);
const SecondConnectedComponent = ContainerCreater(AuthRoute);

export { FirstConnectedComponent as PrivateRoute, SecondConnectedComponent as AuthRoute };
