import React, { Fragment, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { css } from 'twin.macro';

const EditOptionModal = ({ option }) => {
    const dispatch = useDispatch();
    const [label, setLabel] = useState(option?.text || '');
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const addFormDataElementOption = (form) => {
        dispatch({ type: 'ADD_FORM_ELEMENT_OPTION', payload: form });
    };
    const handleSave = () => {
        addFormDataElementOption({ ...option, text: label });
        handleClose();
    };

    useEffect(() => {
        if (show) {
            setLabel(option?.text || '');
        }
    }, [show]);
    return (
        <Fragment>
            <span onClick={handleShow} className="pointer">
                <FaEdit color={'white'} />
            </span>
            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">Edit Option</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input
                        type="text"
                        value={label}
                        onChange={(e) => setLabel(e.target.value)}
                        css={css`
                            padding: 8px 12px;
                            font-size: 16px;
                            border: 2px solid #ccc;
                            border-radius: 4px;
                            margin-bottom: 16px;
                            width: 400px;
                            &:focus {
                                outline: none;
                                border-color: #50aeb0;
                            }
                        `}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            handleSave();
                        }}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default EditOptionModal;
