import React, { useState, Fragment } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FaFilePdf } from 'react-icons/fa';
import PDFFieldMapper from './PDFFieldMapper';
import { Link } from 'react-router-dom';

const SmartPdf = ({ formElements, onUploadPdfTemplate, url }) => {
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    return (
        <Fragment>
            <Button variant="primary" onClick={() => setShowModal(true)}>
                <FaFilePdf /> Convert your document to online Form (Smart PDF)
            </Button>
            {/* <Link to="/smartpdf">Learn more about Smart PDF</Link> */}
            <Modal show={showModal} onHide={() => setShowModal(false)} centered size="xl" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Smart PDF</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body" style={{ minHeight: '70vh' }}>
                    <Fragment>
                        <PDFFieldMapper
                            formElements={formElements}
                            onUploadPdfTemplate={onUploadPdfTemplate}
                            url={url}
                            handleClose={handleClose}
                        />
                    </Fragment>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default SmartPdf;
