export const optionCoordinates = [
    {
        config: {
            templateConfig: {
                x: 0.04774692299756603,
                y: 0.05536332179930796,
                width: 0.03581020590868397,
                height: 0.02768166089965398,
                page: 1,
            },
            rectConfig: {
                x: 53.33331298828125,
                y: 80,
                width: 40,
                height: 40,
                page: 1,
            },
        },
    },
    {
        config: {
            templateConfig: {
                x: 0.04685166784984893,
                y: 0.13771626297577855,
                width: 0.03581020590868397,
                height: 0.02768166089965398,
                page: 1,
            },
            rectConfig: {
                x: 52.33331298828125,
                y: 199,
                width: 40,
                height: 40,
                page: 1,
            },
        },
    },
    {
        config: {
            templateConfig: {
                x: 0.04685166784984893,
                y: 0.09688581314878893,
                width: 0.03581020590868397,
                height: 0.02768166089965398,
                page: 1,
            },
            rectConfig: {
                x: 52.33331298828125,
                y: 140,
                width: 40,
                height: 40,
                page: 1,
            },
        },
    },
];

export const genericFieldData = {
    generic: {
        config: {
            templateConfig: {
                x: 0.021784523713770144,
                y: 0.016574585635359115,
                width: 0.21575649059982094,
                height: 0.04143646408839779,
                page: 1,
            },
            rectConfig: {
                x: 24.33331298828125,
                y: 24,
                width: 241,
                height: 60,
                page: 1,
            },
        },
    },
    checkboxOrRadio: {
        config: {
            templateConfig: {
                x: 0.2464935657907621,
                y: 0.05179558011049724,
                width: 0.04207699194270367,
                height: 0.024861878453038673,
                page: 1,
            },
            rectConfig: {
                x: 275.33331298828125,
                y: 75,
                width: 47,
                height: 36,
                page: 1,
            },
        },
    },
};
export const resizeHandlePositions = {
    'top-left': `left: -5px; top: -5px;`,
    'top-right': `right: -5px; top: -5px;`,
    'bottom-left': `left: -5px; bottom: -5px;`,
    'bottom-right': `right: -5px; bottom: -5px;`,
    'top-center': `left: 50%; top: -5px; transform: translateX(-50%);`,
    'right-center': `right: -5px; top: 50%; transform: translateY(-50%);`,
    'bottom-center': `left: 50%; bottom: -5px; transform: translateX(-50%);`,
    'left-center': `left: -5px; top: 50%; transform: translateY(-50%);`,
};
