import { css } from 'twin.macro';
import { FaCheck } from 'react-icons/fa';
import { AiOutlineUserDelete } from 'react-icons/ai';
import { Image } from 'react-bootstrap';
import ArchivedAvatar from '../../../assets/archivedUser.svg';
import { BootstrapToolTip } from '../../../_elements/ToolTip';
import { VerifiedUserCheck, ArchivedUser } from '../reusableStyles';

const avatarContainer = css`
    position: relative;
    margin: auto;
    width: 3.8rem;
    height: 3.8rem;
    margin: 10px auto;
    .avatar {
        position: relative;
        width: 3.8rem;
        height: 3.8rem;
        border-radius: 12px;
    }
    .noimg-container {
        border: none;
        display: flex;
        margin: auto;
    }
    .noimg-container span {
        margin: auto;
        color: #fff;
        font-size: 10px;
    }
    .noimg-container p {
        margin: auto;
        color: #fff;
        font-size: 14px;
    }
`;

const VerificationBadge = ({ type }) => (
    <BootstrapToolTip text={`${type} Verified`}>
        <span
            css={[
                VerifiedUserCheck,
                type === 'Phone'
                    ? css`
                          top: 10px !important;
                          background: #50aeb0;
                      `
                    : css``,
            ]}
        >
            <FaCheck
                color="white"
                css={css`
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                `}
            />
        </span>
    </BootstrapToolTip>
);

const ArchivedUserAvatar = () => (
    <div css={avatarContainer}>
        <img src={ArchivedAvatar} className="avatar" alt="archived user" />
        <span css={ArchivedUser}>
            <AiOutlineUserDelete
                color="white"
                css={css`
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                `}
            />
        </span>
    </div>
);

const UserAvatar = ({ avatar, name }) => (
    <div css={avatarContainer}>
        <Image src={avatar} className="avatar" alt={name} width="60" />
    </div>
);

const NoImageAvatar = ({ color, initials, isEmailVerified, isPhoneVerified }) => (
    <div css={avatarContainer}>
        <div className="noimg-container avatar" style={{ background: color }}>
            <p>{initials}</p>
            {isEmailVerified && <VerificationBadge type="Email" />}
            {isPhoneVerified && <VerificationBadge type="Phone" />}
        </div>
    </div>
);

const AdminTablesImg = ({ item }) => {
    const { avatar, name, status, color, initials, isEmailVerified, isPhoneVerified } = item;

    if (status === 'archive') return <ArchivedUserAvatar />;

    return avatar ? (
        <div css={avatarContainer}>
            <UserAvatar avatar={avatar} name={name} />
            {isEmailVerified && <VerificationBadge type="Email" />}
            {isPhoneVerified && <VerificationBadge type="Phone" />}
        </div>
    ) : (
        color && initials && (
            <NoImageAvatar
                color={color}
                initials={initials}
                isEmailVerified={isEmailVerified}
                isPhoneVerified={isPhoneVerified}
            />
        )
    );
};

export default AdminTablesImg;
